.homepage {
    background: var(--rgba-primary-1);
    display: flex;
    min-height: 100vh;
}

.homepage .left-aside {
    background: #fff;
    padding-top: 5rem;
    max-width: 35rem;
    width: 100%;
    z-index: 1;
    position: relative;
}

.homepage .left-aside:after {
    content: "";
    clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
    width: 8.75rem;
    height: 100%;
    position: absolute;
    right: -8.75rem;
    z-index: -1;
    top: 0;
    background: #fff;
    box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.homepage .left-aside .aside-image {
    min-height: 28.125rem;
    margin: auto 0;
    min-width: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 87.5rem) {
    .homepage .left-aside {
        max-width: 22.5rem;
    }
}

@media only screen and (max-width: 61.9375rem) {
    .homepage .left-aside {
        max-width: 100%;
        padding-top: 0;
    }

    .homepage .left-aside:after {
        content: none;
    }
}

@media only screen and (max-width: 35.9375rem) {
    .homepage .left-aside .aside-image {
        min-height: 18.75rem;
    }
}

.homepage-content {
    background: #fff;
    box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
    border-radius: 0.3125rem;
}

[data-theme-version="dark"] .homepage-content {
    background: #202020;
    box-shadow: none;
}

.homepage-content.style-1 {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(20px);
}

.homepage-content.style-1 .form-control {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0.3125rem;
}

.homepage-content.style-1 .user-icon {
    height: 6.25rem;
    background: var(--primary);
    width: 6.25rem;
    text-align: center;
    border-radius: 6.25rem;
    line-height: 6.25rem;
    margin-left: auto;
    font-size: 3.75rem;
    text-align: center;
    color: white;
    margin-right: auto;
    margin-top: -6.25rem;
    margin-bottom: 1.25rem;
}

.homepage-content.style-2 {
    background: transparent;
    box-shadow: none;
    max-width: 33.125rem;
    width: 100%;
}

.homepage-content.style-2 .form-control {
    border: 0;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0rem 0.9375rem rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 35.9375rem) {
    .homepage-content.style-2 .auth-form {
        padding: 1.875rem 0rem;
    }
}


.homepage .right-aside {
    background: #fff;
    padding-top: 5rem;
    max-width: 35rem;
    width: 100%;
    z-index: 1;
    position: relative;
}

.homepage .right-aside:after {
    content: "";
    clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
    width: 8.75rem;
    height: 100%;
    position: absolute;
    right: -8.75rem;
    z-index: -1;
    top: 0;
    background: #fff;
    box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.homepage .right-aside .aside-image {
    min-height: 28.125rem;
    margin: auto 0;
    min-width: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 87.5rem) {
    .homepage .right-aside {
        max-width: 22.5rem;
    }
}

@media only screen and (max-width: 61.9375rem) {
    .homepage .right-aside {
        max-width: 100%;
        padding-top: 0;
    }

    .homepage .right-aside:after {
        content: none;
    }
}

@media only screen and (max-width: 35.9375rem) {
    .homepage .right-aside .aside-image {
        min-height: 18.75rem;
    }
}


.header-area {
    position: absolute;
    height: auto;
    width: 100%;
    background-color: transparent;
    z-index: 999;
}

.main-header-area {
    position: absolute;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

.header-area .navbar {
    padding: 0;
}

.navbar-brand .logo {
    max-height: 50px;
}

.header-items .nav-link {
    font-size: 15px;
    font-weight: 500;
    color: #666;
    text-transform: uppercase;
}

.header-items .nav-item:hover .nav-link,
.header-items .nav-item:focus .nav-link,
.header-items .nav-item.active .nav-link {
    color: #444;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar-light .navbar-toggler {
    border: none;
}

.mega-menu {
    width: 900px;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow: hidden;
}

.mega-menu.blog-menu {
    width: 250px;
}

.dropdown:hover .mega-menu {
    display: block;
    -webkit-animation: dropdownAnimation 0.3s ease-in-out;
    animation: dropdownAnimation 0.3s ease-in-out;
}

@-webkit-keyframes dropdownAnimation {
    0% {
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(20px);
        transform: translateX(-50%) translateY(20px);
        visibility: hidden;
        opacity: 0;
    }

    100% {

        visibility: visible;
        opacity: 1;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(0);
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes dropdownAnimation {
    0% {
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(20px);
        transform: translateX(-50%) translateY(20px);
        visibility: hidden;
        opacity: 0;
    }

    100% {

        visibility: visible;
        opacity: 1;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(0);
        transform: translateX(-50%) translateY(0);
    }
}

.dropdown-headings span {
    font-size: 18px;
    line-height: 1;
}

.dropdown-headings .slag {
    font-size: 13px;
}

.dropdown-btn .btn {
    font-size: 14px;
    padding: 12px 16px;
}

.dropdown-item {
    font-size: 15px;
    color: #777;
    padding: .25rem 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #444;
    background-color: inherit;
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
}

.single-menu .page-title {
    font-size: 15px;
    font-weight: 500;
    color: #444;
}

.mega-menu .shape-1::before {
    width: 19rem;
    height: 15rem;
    top: 0;
    left: -4rem;
    -webkit-transform: rotate(44deg);
    transform: rotate(44deg);
}

.navbar-sticky {
    position: fixed;
    width: 100%;
    height: 60px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
    border-bottom: none;
    z-index: 1000 !important;
}

.navbar-sticky.hide {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.blog .navbar-sticky {
    background: rgb(39, 45, 58) repeat scroll 0 0;
}


/*New style*/


.homepage-3 .welcome-area .subscribe-form {
    width: 100%;
}

.homepage-3 .welcome-area .subscribe-form input {
    text-indent: 0;
}

.homepage-3 .welcome-area {
}

/* ***********************************
:: 8.1 WELCOME AREA HOMEPAGE-3 CSS
**************************************/
@media (min-width: 992px) {
    .homepage-2 .welcome-thumb {
        max-width: 400px;
    }
}

.homepage-3 .welcome-area .subscribe-form {
    position: relative;
    display: block;
    width: 80%;
}

.homepage-3 .welcome-area .subscribe-form input {
    font-size: 14px;
    border-radius: 10px;
    text-indent: 3%;
    -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
}

.homepage-3 .welcome-area .subscribe-form button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.homepage-3 .welcome-area .shape-bottom svg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

@media (max-width: 991px) {
    .preview .welcome-area {
        background-position: unset;
    }
}

@media (max-width: 767px) {
    .preview .welcome-area {
        height: 500px !important;
        padding-top: 0;
    }
}

.shape-bottom {
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 0;
    right: 0;
}

.welcome-area .shape-bottom {
    z-index: -1;
}

.homepage-3 .welcome-area .shape-bottom svg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

/* ******************************
:: 3.0 WELCOME AREA CSS
****************************** */
@media (max-width: 991px) {
    .welcome-area,
    .inner .welcome-area {
        height: 700px !important;
    }

    .homepage-3 .welcome-area .subscribe-form {
        width: 100%;
    }

    .homepage-5 .welcome-area {
        height: 1150px !important;
    }
}

@media (max-width: 767px) {
    .welcome-area,
    .homepage-5 .welcome-area,
    .inner-pages.signup .h-100vh {
        height: 100% !important;
        padding-top: 90px;
    }

    .welcome-intro {
        margin-top: 30px;
    }

    .welcome-thumb {
        max-width: 270px;
        padding-top: 20px;
    }

    .homepage-4 .welcome-thumb {
        max-width: 350px;
    }
}

@media (max-width: 575px) {
    .welcome-intro {
        margin-top: 0;
    }

    .homepage-3 .welcome-area .subscribe-form input {
        text-indent: 0;
    }

    .welcome-thumb {
        max-width: 230px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ptb_180 {
        padding: 130px 0;
    }

    .ptb_150 {
        padding: 100px 0;
    }

    .ptb_100 {
        padding: 70px 0;
    }
}

@media (max-width: 991px) {
    .res-margin {
        margin-bottom: 45px;
    }
}

@media (max-width: 767px) {

    .inner-wrapper h1,
    .inner-wrapper h2 {
        font-size: 30px;
    }

    .ptb_180 {
        padding: 130px 0;
    }

    .ptb_150,
    .ptb_100 {
        padding: 50px 0;
    }

    .res-margin {
        margin-bottom: 30px;
    }
}

@media (max-width: 575px) {

    .inner-wrapper h1,
    .inner-wrapper h2 {
        font-size: 24px;
    }

    .avatar-lg {
        height: 4rem;
        width: 4rem;
    }

    .container-fluid {
        padding: 0 3%;
    }

    .ptb_180 {
        padding: 80px 0;
    }

    .ptb_150,
    .ptb_100 {
        padding: 30px 0;
    }
}

.pt_0 {
    padding-top: 0;
}

.ptb_50 {
    padding: 50px 0;
}

.ptb_100 {
    padding: 100px 0;
}

.ptb_150 {
    padding: 150px 0;
}

.ptb_180 {
    padding: 180px 0;
}

.card-flex {
    flex: 1 1 0;
    width: 100%;
}

.sandbox-layout {
    width: 100%;
    height: 700px;
    border: 3px black solid;
    border-radius: 4px;
    overflow: hidden;
}

.login-logo{
    width: 90%;
    height: auto;
}
